import styled from "styled-components"

export const StyledAboutWrapper = styled.div`
  width: 100%;
`
export const StyledAboutContainer = styled.div`
  max-width: 1000px;
  margin: auto;
  height: 100%;
`
export const StyledAboutContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 25px 0px;
  display: flex;
  flex-wrap: wrap;
`
export const StyledAboutItem = styled.div`
  flex: 1 1 300px;
  padding: 15px;
  margin: 10px 10px;
  background-color: #fbf8f8;
`
export const StyledAboutItemTitle = styled.h3`
  text-align: center;
  text-transform: uppercase;
  font-weight: 800;
  color: #333;
  padding-bottom: 20px;
`
export const StyledAboutItemText = styled.p`
  text-align: center;
  color: #555;
  line-height: 1.5rem;
`
export const StyledAboutMargin = styled.div`
  padding: 80px 0px 0px 0px;
`
